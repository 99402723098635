<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              :span="24"
              :xs="24"
              :sm="24"
              :md="24"
              :lg="24"
              :xl="24"
              v-if="!props.employee?.uuid"
            >
              <ElFormItem
                prop="employee_uuid"
                :label="$t('employee')"
                class="autocomplete-new-entity"
                :class="form.employee_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getEmployeeKey()"
                  v-loading="getEmployeeKey() === 0"
                  v-model="form.employee_uuid"
                  :remote-method="searchEmployees"
                  :placeholder="$t('select_an_employee')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchEmployees()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewEmployeeDialog = true"
                />
                <Icons
                  v-if="form.employee_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/human-resources/employees', {
                      uuid: form.employee_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="permit_uuid"
                :label="$t('permit')"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getPermitKey()"
                  v-loading="getPermitKey() === 0"
                  v-model="form.permit_uuid"
                  :remote-method="searchPermits"
                  :placeholder="$t('select_a_permit')"
                  remote
                  filterable
                  autocomplete
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchPermits()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewPermitDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="start_date" :label="$t('start_date')">
                <ElDatePicker
                  v-model="form.start_date"
                  class="!w-full"
                  type="date"
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="end_date" :label="$t('end_date')">
                <ElDatePicker
                  v-model="form.end_date"
                  class="!w-full"
                  type="date"
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="containerWidth <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewEmployeeDialog"
      :width="width <= 600 ? '100%' : '400px'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_employee') }}
        </span>
      </template>
      <QuickCreateEmployeeDialog
        @create=";(showNewEmployeeDialog = false), createEmployee($event)"
        @cancel="showNewEmployeeDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewPermitDialog"
      :width="width <= 600 ? '100%' : '400px'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_permit') }}
        </span>
      </template>
      <QuickCreatePermitDialog
        :key="customKey"
        @create=";(showNewPermitDialog = false), createPermit($event)"
        @cancel="showNewPermitDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import moment from 'moment'
import useVuelidate from '@vuelidate/core'
import { orderBy } from 'lodash'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_EMPLOYEE_PERMIT from '~/graphql/employee-permits/mutation/createEmployeePermit.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const props = defineProps({
  employee: {
    type: Object,
    default: () => ({})
  }
})

const form = reactive({
  employee_uuid: props.employee?.uuid || '',
  permit_uuid: '',
  start_date: '',
  end_date: ''
})

onMounted(() => {
  initialEmployees()
  initialPermits()
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewEmployeeDialog = ref(false)
const showNewPermitDialog = ref(false)
let { containerWidth } = getFormInitialVariables()
let { newWindowItem } = getTableInitialVariables()

const rules = {
  employee_uuid: { required },
  start_date: { required },
  permit_uuid: { required },
  end_date: { required }
}

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  employee_uuid: [
    {
      required: true,
      message: t('employee_is_required'),
      trigger: 'blur'
    }
  ],
  start_date: [
    {
      required: true,
      message: t('start_date_is_required'),
      trigger: 'blur'
    }
  ],
  end_date: [
    {
      required: true,
      message: t('end_date_is_required'),
      trigger: 'blur'
    }
  ],
  permit_uuid: [
    {
      required: true,
      message: t('permit_is_required'),
      trigger: 'blur'
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_EMPLOYEE_PERMIT, {
    employee_uuid: form.employee_uuid,
    start_date: form.start_date
      ? moment(form.start_date).format('YYYY-MM-DD HH:mm:ss')
      : '',
    end_date: form.end_date
      ? moment(form.end_date).format('YYYY-MM-DD HH:mm:ss')
      : '',
    permit_uuid: form.permit_uuid,
    year: parseInt(moment(form.start_date).format('YYYY'))
  })
  if (response.error) {
    loading.value = false
    if (
      response.error.message.includes(
        'The permit cannot be created because you already have a permit in the date range provided'
      )
    ) {
      $showError(t('permit_overlapped'))
    } else if (
      response.error.message.includes(
        'The end date cannot be less than the start date'
      )
    ) {
      $showError(t('start_date_cannot_be_after_end_date'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createEmployeePermit ?? null)
    loading.value = false
  }
}

const createEmployee = (event) => {
  if (event?.uuid) {
    form.employee_uuid = event.uuid
    form.employee = event
    searchEmployees(event.user.first_name, form.employee, null)
  }
}

const createPermit = (event) => {
  if (event?.uuid) {
    form.permit_uuid = event.uuid
    form.permit = event
    searchPermits(event.name, form.permit, null)
  }
}
</script>

<style>
</style>